import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import Input from "../../common/components/Input";
import PopUp from "../../common/components/PopUp";
import { getOneOrder, updateOrder } from "../../API/repositories/order";
import withRequestProvider from "../../common/hocs/withRequestProvider";
import { useRequestsContext } from "../../common/hooks/requestHook";
import Loading from "../../common/components/Loading";
import SelectInput from "../../common/components/SelectInput";
import { getCurrencies } from "../../API/repositories/currency";
import { getMarkets } from "../../API/repositories/market";
import { useMessageQueue } from "../../common/messageProvider/";

const Flex = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

const Edit = ({ data: { id, currency }, setEditShow, currierOptions }) => {
  const [contact, setContact] = useState({});
  const [orderItems, setOrderItems] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [currenciesOptions, setCurrenciesOptions] = useState();
  const [selectedCountires, setSelectedCountires] = useState();
  const [selectedPost, setSelectedPost] = useState();
  const [countries, setCountries] = useState();

  const cityRef = useRef();
  const postalCodeRef = useRef();
  const streetRef = useRef();
  const fullNameRef = useRef();
  const emailRef = useRef();
  const additionalInfoRef = useRef();
  const phoneNumberRef = useRef();

  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const { addMessage } = useMessageQueue();

  const handleSaveOrder = async (e) => {
    e.preventDefault();

    const payload = Object();
    payload.city = cityRef.current ? cityRef.current.value : contact.city;
    payload.postal_code = postalCodeRef.current
      ? postalCodeRef.current.value
      : contact.city;
    payload.street = streetRef.current ? streetRef.current.value : contact.city;
    payload.full_name = fullNameRef.current
      ? fullNameRef.current.value
      : contact.city;
    payload.email = emailRef.current ? emailRef.current.value : contact.city;
    payload.order_items = orderItems;
    payload.additional_info = additionalInfoRef.current
      ? additionalInfoRef.current.value
      : contact.additional_info;
    payload.currency = selectedCurrency.value;
    payload.phone_number_for_courier = phoneNumberRef.current.value;
    payload.curier = selectedPost._id;
    payload.country = selectedCountires.value;

    const response = await makeRequest(updateOrder.bind(null, id, payload));

    if (response.data) {
      setEditShow(() => false);
    }
  };

  const loadCountries = async () => {
    const response = await makeRequest(getMarkets.bind());

    if (!response?.data) {
      return addMessage("Error loading data", "error");
    }

    const tempCountries = response.data.map((c) => ({
      label: c.name,
      value: c.short,
    }));

    return tempCountries;
  };

  const loadData = async () => {
    const response = await makeRequest(getOneOrder.bind(null, id));

    if (response?.data) {
      setContact(() => response.data.contact);
      setOrderItems(() =>
        response.data.shipping?.order_items?.length
          ? response.data.shipping.order_items
          : response.data.order_items
      );

      const tempCountries = await loadCountries();
      setCountries(() => tempCountries);
      setSelectedCountires(() =>
        tempCountries.find((c) => c.value === response.data.contact.country)
      );

      setSelectedPost(() =>
        currierOptions.find(
          (p) => p._id === response.data.order.shipping.curier_lms_id
        )
      );
    }

    const currenciesReponse = await makeRequest(getCurrencies.bind());
    if (currenciesReponse?.data) {
      const currenciesMappedToOptions = currenciesReponse?.data.map((curr) => ({
        label: curr.name,
        value: curr.short,
      }));

      setCurrenciesOptions(() => currenciesMappedToOptions);
      setSelectedCurrency(() =>
        currenciesMappedToOptions.find((curr) => curr.value === currency)
      );
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {(hasUnfilledRequest(updateOrder) || hasUnfilledRequest(getOneOrder)) && (
        <Loading />
      )}
      <PopUp setShow={setEditShow}>
        <h4>Contact info Edit:</h4>
        <br></br>
        <SelectInput
          name="Country"
          selected={selectedCountires}
          options={countries}
          setSelected={setSelectedCountires}
          selectWidth="320"
          width="140"
        />
        <SelectInput
          name="Curier"
          selected={selectedPost}
          options={currierOptions}
          setSelected={setSelectedPost}
          selectWidth="320"
          width="140"
        />
        <Input
          name="City"
          value={contact.city}
          inputRef={cityRef}
          inputWidth={320}
        />
        <Input
          name="Postal code"
          value={contact.postal_code}
          inputRef={postalCodeRef}
          inputWidth={320}
        />
        <Input
          name="Street"
          value={contact.street}
          inputRef={streetRef}
          inputWidth={320}
        />
        <Input
          name="Full name"
          value={contact.full_name}
          inputRef={fullNameRef}
          inputWidth={320}
        />
        <Input
          name="Email"
          value={contact.email}
          inputRef={emailRef}
          inputWidth={320}
        />
        <Input
          name="Phone number"
          value={contact.phone_number_for_courier || contact.phone_number}
          inputRef={phoneNumberRef}
          inputWidth={320}
        />
        <Input
          name="Additional INFO"
          value={contact.additional_info}
          inputRef={additionalInfoRef}
          inputWidth={320}
        />
        <SelectInput
          name="Currency"
          selected={selectedCurrency}
          options={currenciesOptions}
          setSelected={setSelectedCurrency}
          selectWidth="320"
          width="140"
        />
        <br></br>
        {orderItems && <h4>Order Items:</h4>}
        <br></br>
        {orderItems &&
          orderItems.map((item, i) => (
            <Flex key={i}>
              <Input
                name="Variant"
                value={item.product_variant}
                width={80}
                inputWidth={80}
                onChange={(e) => {
                  item.product_variant = e.target.value;
                }}
              />
              <Input
                name="Price"
                value={item.price}
                width={80}
                inputWidth={80}
                onChange={(e) => {
                  item.price = parseInt(e.target.value);
                }}
              />
            </Flex>
          ))}

        <button
          className="btn btn-warning"
          style={{ float: "right", marginTop: "20px", marginBottom: "-20px" }}
          onClick={(e) => handleSaveOrder(e)}
        >
          Save
        </button>
      </PopUp>
    </>
  );
};

export default withRequestProvider(Edit);
